import React, { Fragment } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/layout";
import { Button } from "../components/button";

export default function Template({ data }) {
  const { markdownRemark, parentPage } = data;
  const { frontmatter, html } = markdownRemark;
  const { cartloom } = frontmatter;
  const { frontmatter: parentFrontmatter, fields: parentFields } = parentPage;

  const breadcrumbs = [
    { name: "Home", href: "/" },
    {
      name: "Shop",
      href: "/shop/"
    },
    { name: parentFrontmatter.title, href: parentFields.slug },
    { name: frontmatter.title }
  ];

  return (
    <Fragment>
      <Layout
        breadcrumbs={breadcrumbs}
        preambleText={frontmatter.preambleText}
        metaDescription={frontmatter.metaDescription}
      >
        <Container>
          <ImageContainer>
            {frontmatter.image &&
              frontmatter.image.childImageSharp &&
              frontmatter.image.childImageSharp.fluid && (
                <Img fluid={frontmatter.image.childImageSharp.fluid} />
              )}
          </ImageContainer>
          <DetailsContainer>
            <ProductName>{frontmatter.title}</ProductName>
            <Price>{frontmatter.price}</Price>
            <Description dangerouslySetInnerHTML={{ __html: html }} />
            {cartloom &&
              cartloom.map(({ cartloomId, buttonLabel }) => (
                <form
                  key={cartloomId}
                  action={`https://hydrorace.cartloom.com/cart/add/${cartloomId}`}
                  method="post"
                  className="cartloom-addtocart"
                >
                  <input type="hidden" name="cart" value="add" />
                  <input type="hidden" name="pid" value={cartloomId} />
                  <input type="hidden" name="cost" value={frontmatter.price} />
                  <BuyButton type="submit">{buttonLabel}</BuyButton>
                </form>
              ))}
          </DetailsContainer>
        </Container>
      </Layout>
    </Fragment>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
`;

const ImageContainer = styled.div`
  flex: 1;
  flex-basis: 16rem;
  padding: 1rem;

  > .gatsby-image-wrapper {
    border-radius: 1rem;
    overflow: hidden;
  }
`;

const DetailsContainer = styled.div`
  flex-grow: 1;
  flex-basis: 25rem;
  padding: 1rem;
`;

const ProductName = styled.h1`
  margin: 0;
`;

const Price = styled.span`
  display: block;
  margin-top: 1rem;
  font-size: 1.4em;
`;

const Description = styled.div`
  max-width: 60ch;

  > p {
    margin: 1em 0 0;
  }
`;

const BuyButton = styled(Button)`
  margin-top: 1.5rem;
`;

export const productPageQuery = graphql`
  query($id: String!, $parentSlug: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        price
        metaDescription
        cartloom {
          cartloomId
          buttonLabel
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    parentPage: markdownRemark(fields: { slug: { eq: $parentSlug } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
